import React, {PureComponent} from 'react';
import {
  Label,
} from 'mw-style-react';
import cn from 'classnames';
import './Terms.scss';
import Content from './components/Content';
import { withIntl } from '../../i18n';
import content from './content';


class Terms extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      terms: 'customer',
    };
  }

  handleChange(terms) {
    this.setState({
      terms
    });
  }

  render() {
    const { terms } = this.state;

    return (
      <div>
        <div className="terms__header">
          <div className="terms__header__title">
            <h1>{'Corezoid Terms & Policies'}</h1>
          </div>
          {/* <Label
              value={'Explore the power of Corezoid for each team member'}
              className="terms__header__subTitle"
            />
          */}
        </div>
        <div className="grid-wrapper terms">
          <div className="col-3 terms__wrap__tree">
            <Label value={'Contents'} className="terms__wrap__tree__title"/>
            <Label value={'TERMS OF SERVICE'} className="terms__wrap__tree__subTitle"/>
            <Label
              value={'Customer agreement'}
              className={cn('terms__wrap__tree__link', {active: terms === 'customer'})}
              onClick={() => this.handleChange('customer')}
            />
            <Label value={'POLICIES'} className="terms__wrap__tree__subTitle"/>
            <Label
              value={'Privacy Policy'}
              className={cn('terms__wrap__tree__link', {active: terms === 'privacy'})}
              onClick={() => this.handleChange('privacy')}
            />
            <Label
              value={'Cookie Policy'}
              className={cn('terms__wrap__tree__link', {active: terms === 'cookie'})}
              onClick={() => this.handleChange('cookie')}
            />
          </div>
          <div className="col-9 terms__wrap__content">
            <Content content={content[terms]} />
          </div>
        </div>
      </div>
    );
  }
}

export default withIntl(Terms);
