import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './Content.scss';

class Content extends PureComponent {
  render() {
    const { title, date, data } = this.props.content;

    return (
      <div className="terms__content">
        <span className="terms__content__title">{title}</span>
        <span className="terms__content__date">{date}</span>
        {data.map((part, index) => (
          <div className="terms__content" key={index}>
            {part.title &&
              <span className="terms__content__subTitle">{part.title}</span>
            }
            {part.content.map((item, index) => (
              <div key={index}>
                <div className="terms__content__count__wrap">
                  {part.type === 'numberList' &&
                    <span
                      className="terms__content__count"
                    >
                      {`${index + 1}. `}
                    </span>
                  }
                  {item.type === 'table' ?
                    <table className="terms__content__table">
                      {item.value.map((row, indexRow) => (
                        <tr key={indexRow} className="terms__content__table__row">
                          {
                            row.map((cell, indexCell) => (
                              <th
                                key={indexCell}
                                className={`terms__content__table__cell terms__content__table__row${indexRow}`}
                              >
                                {cell}
                              </th>
                            ))
                          }
                        </tr>
                      ))}
                    </table> :
                    (item.content ?
                      <div>
                        {item.content.map((item2, index) => (
                          <div key={index}>
                            <div className="terms__content__count__wrap">
                              <span
                                className="terms__content__content"
                              >
                                {item2.value}
                              </span>
                            </div>
                            {item2.data && item2.data.map((item3, index) => (
                              <div key={index} className="terms__content__count__wrap terms__content__count__level2">
                                {item2.type === 'numberList' &&
                                  <span
                                    className="terms__content__count"
                                  >
                                    {`${index + 1}. `}
                                  </span>
                                }
                                <span
                                  className="terms__content__content"
                                >
                                 {item3.value}
                                </span>
                              </div>
                            ))}
                          </div>
                        ))}
                      </div> :
                      <span
                        className="terms__content__content"
                      >
                        {item.value}
                      </span>
                    )
                  }
                </div>
                {item.data && item.data.map((item, index) => (
                  <div key={index} className="terms__content__count__wrap terms__content__count__level2">
                    <div className="terms__content__count">&bull;</div>
                    <span
                      className="terms__content__content"
                    >
                      {item.value}
                    </span>
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }
}

Content.propTypes = {
  content: PropTypes.object
};


export default Content;
