import React from 'react';
import Layout from '../components/Layout';
import TermsPage from '../templates/Terms';
import { withIntl } from '../i18n';

const TermsAndConditions = (props) => (
  <Layout page={'terms'} {...props}>
    <TermsPage {...props}/>
  </Layout>
);

export default withIntl(TermsAndConditions);

